import React from "react"
import Hakusi from '../../layouts/hakusi'


export default () => (


	<Hakusi>


	<div className="pad butinuki" />

	<section class="kanaami butinuki t-center">

		<h1 class='moji_kage_koi'> お支払い状況に問題があります </h1>
		<p className='t-bold' style={ { margin: `5em` } }>
				教材に対するお支払い状況に問題があるため、<br />
				教材の使用が制限されています。<br />
				<br />
				ご購入時のメールアドレスに、<br />
				お支払いに関するご案内をお送りしていますので、<br />
				案内に沿ってお手続きください。<br />
				その後、下記のメールアドレスにご連絡いただければ<br />
				制限を解除いたします。<br />
				<br/>
				万一、メールを紛失した、メールが届いていない、<br />
				この表示に身に覚えがない等の場合も、<br />
				下記のメールアドレスよりその旨お問い合わせください。<br />
				<br/>
				なお、利用規約にあります通り、<br />
				お支払いいただけない場合は<br />
				法的措置を取らせていただきますので、<br />
				この画面をご覧になった後は<br />
				早急にご連絡いただきますよう<br />
				お願いいたします。<br/>
				<br/>
				お問い合わせ先：<br />
				i@elvn.tokyo
				</p>

	</section>
	<div className="pad butinuki" />


</Hakusi>
)
