import React from 'react'
import Helmet from 'react-helmet'
import { Link } from "gatsby"
import lazysizes from 'lazysizes'
import './global.css'

import HakusiFooter from '../components/hakusi-footer'

export default ({ children }) => {

		// // メルマガページから変遷した時に、背景を白に戻す機能
		// if (typeof document !== `undefined`) {
		// 	document.body.classList.remove('haikei-kuro')
		// }

	return(
		<>

			<Helmet
				meta={[
					{
						name: `robots`,
						content: `noindex noarchive`,
					}
				]}
			/>


			<main class="b-center seigen">

				{children}

			</main>


			<HakusiFooter />

		</>
	)
}